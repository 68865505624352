import { ComponentProps, FC, forwardRef } from "react";

import { cn } from "@lib/utils";
import { FieldApi, Validator } from "@tanstack/react-form";

export type InputProps = ComponentProps<"input">;

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
    return (
        <input
            type={type}
            className={cn(
                "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                className,
            )}
            ref={ref}
            {...props}
        />
    );
});
Input.displayName = "Input";

export type FieldAPIString = FieldApi<
    unknown,
    string,
    Validator<unknown, unknown> | undefined,
    Validator<unknown, unknown> | undefined,
    string
>;

export type FieldAPINumber = FieldApi<
    unknown,
    string,
    Validator<unknown, unknown> | undefined,
    Validator<unknown, unknown> | undefined,
    number
>;

interface FormInputLabelProps {
    field: FieldAPIString | FieldAPINumber;
    label?: string;
    labelDescription?: string;
}

const FormInputLabel: FC<FormInputLabelProps> = ({ label, labelDescription, field }) => (
    <label htmlFor={field.name} style={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontSize: "1rem" }}>{label}</span>
        {labelDescription && <i style={{ fontSize: "0.8rem" }}>{labelDescription}</i>}
    </label>
);

interface FormInputErrorProps {
    field: FieldAPIString | FieldAPINumber;
}

const FormInputErrors: FC<FormInputErrorProps> = ({ field }) => {
    if (!field.state.meta.errors.length) {
        return null;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {field.state.meta.errors.map((error, index) => (
                <span key={index} style={{ color: "red", fontSize: "0.8rem" }}>
                    {error}
                </span>
            ))}
        </div>
    );
};

export interface FormInputProps extends Omit<InputProps, "type" | "id" | "name" | "value" | "onChange" | "onBlur"> {
    field: FieldAPIString;
    label?: string;
    labelDescription?: string;
    type?: "text" | "password" | "email" | "date";
}

const FormInput: FC<FormInputProps> = ({ field, label, labelDescription, type, ...props }) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
        <FormInputLabel label={label} labelDescription={labelDescription} field={field} />
        <Input
            type={type ?? "text"}
            id={field.name}
            name={field.name}
            value={field.state.value}
            onChange={(e) => field.handleChange(e.target.value)}
            onBlur={field.handleBlur}
            {...props}
        />
        <FormInputErrors field={field} />
    </div>
);
FormInput.displayName = "FormInput";

export interface FormInputNumberProps
    extends Omit<InputProps, "type" | "id" | "name" | "value" | "onChange" | "onBlur"> {
    field: FieldAPINumber;
    label?: string;
    labelDescription?: string;
}

const FormInputNumber: FC<FormInputNumberProps> = ({ field, label, labelDescription, ...props }) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
        <FormInputLabel label={label} labelDescription={labelDescription} field={field} />
        <Input
            type="number"
            id={field.name}
            name={field.name}
            value={field.state.value}
            onChange={(e) => field.handleChange(parseInt(e.target.value))}
            onBlur={field.handleBlur}
            {...props}
        />
        <FormInputErrors field={field} />
    </div>
);
FormInputNumber.displayName = "FormInputNumber";

export interface FormInputRadioProps extends Omit<FormInputProps, "type"> {
    options: { value: string; label?: string }[];
}

const FormInputRadio: FC<FormInputRadioProps> = ({ field, label, labelDescription, options, ...props }) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
        <FormInputLabel label={label} labelDescription={labelDescription} field={field} />
        <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
            {options.map((option) => (
                <div key={option.value} style={{ display: "flex", flexDirection: "row", gap: "0.4rem" }}>
                    <input
                        type="radio"
                        id={option.value}
                        name={field.name}
                        value={option.value}
                        checked={field.state.value === option.value}
                        onChange={() => field.handleChange(option.value)}
                        {...props}
                    />
                    <label htmlFor={option.value}>{option.label ?? option.value}</label>
                </div>
            ))}
        </div>
        <FormInputErrors field={field} />
    </div>
);
FormInputRadio.displayName = "FormInputRadio";

export interface FormInputSelectProps extends ComponentProps<"select"> {
    field: FieldAPIString;
    label?: string;
    labelDescription?: string;
    options: { value: string; label?: string }[];
}

const FormInputSelect: FC<FormInputSelectProps> = ({
    field,
    label,
    labelDescription,
    options,
    className,
    ...props
}) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}>
        <FormInputLabel label={label} labelDescription={labelDescription} field={field} />
        <select
            id={field.name}
            name={field.name}
            value={field.state.value}
            onChange={(e) => field.handleChange(e.target.value)}
            onBlur={field.handleBlur}
            className={cn(
                "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
                className,
            )}
            {...props}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label ?? option.value}
                </option>
            ))}
        </select>
        <FormInputErrors field={field} />
    </div>
);
FormInputSelect.displayName = "FormInputSelect";

export { Input, FormInput, FormInputNumber, FormInputRadio, FormInputSelect };
