"use client";

import Link from "next/link";

import { useEffect, useState } from "react";

import { User } from "next-auth";
import { signIn, signOut } from "next-auth/react";
import { useServerAction } from "zsa-react";

import { getUserInfos } from "@lib/actions/user";

import { Button } from "./ui/button";
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarGroup,
    SidebarGroupContent,
    SidebarGroupLabel,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
} from "./ui/sidebar";

export default function AppSidebar() {
    const [userInfos, setUserInfos] = useState<User | null>(null);

    const { execute, isPending, isSuccess, isError } = useServerAction(getUserInfos, {
        onFinish: (res) => {
            const [user, error] = res;
            console.log("getUserInfos onFinish", { user, error });
            console.log(error );
            if (user == null) {
                // signIn("keycloak").catch(console.error);
            }
        },
    });

    useEffect(() => {
        async function fetchUserInfos() {
            const [fetchedUserInfos] = await execute();

            console.log({ fetchedUserInfos });
            setUserInfos(fetchedUserInfos);
        }
        fetchUserInfos().catch(console.error);
    }, [execute]);

    return (
        <Sidebar>
            <SidebarHeader></SidebarHeader>
            <SidebarContent>
                <SidebarGroup>
                    <SidebarGroupLabel>FB Leads</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton asChild>
                                    <Link href="/fb_leads/reprocess">Re-import</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton asChild>
                                    <Link href="/fb_leads/list">List</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup>
                    <SidebarGroupLabel>MSO</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton asChild>
                                    <Link href="/mso/listPipelines">Failed pipelines</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            <SidebarMenuItem>
                                <SidebarMenuButton asChild>
                                    <Link href="/mso/listTasks">Visualize by ID</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup>
                    <SidebarGroupLabel>Organizations</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton asChild>
                                    <Link href="/organizations/new">Create</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>

                <SidebarGroup>
                    <SidebarGroupLabel>Sourcing campaigns</SidebarGroupLabel>
                    <SidebarGroupContent>
                        <SidebarMenu>
                            <SidebarMenuItem>
                                <SidebarMenuButton asChild>
                                    <Link href="/campaign/new">Create</Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                        </SidebarMenu>
                    </SidebarGroupContent>
                </SidebarGroup>
            </SidebarContent>

            <SidebarFooter>
                {isPending && <p>Loading...</p>}
                {isSuccess && (
                    <>
                        <p>Welcome {userInfos?.name}</p>
                        <Button onClick={() => signOut({ redirectTo: "/" }).catch(console.error)}>Logout</Button>
                    </>
                )}
                {isError && <>
                    <p>Not authenticated</p>
                    <Button onClick={() => { signIn("keycloak").catch(console.error); }}>
                        Sign-in
                    </Button>
                </>
                }
            </SidebarFooter>
        </Sidebar>
    );
}
